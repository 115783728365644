import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {inferStepValueGroup} from 'helpers';
import {
  AddonsConfig,
  AvailableActivationMethods,
  AvailableAddons,
  AvailableCountries,
  AvailableOperators,
  AvailableShippingMethods,
  AvailableSimTypes,
  AvailableSteps,
  Cart,
  CustomerAvailableCompanyTypes,
  CustomerAvailableDocuments,
  DebugInfo,
  DynamicEntityKey,
  EntityKey,
  EntityValue,
  FunnelConfiguration,
  FunnelInfo,
  Offer,
  OfferPromoInfo,
  OrderSummary,
  PaymentAvailableMethods,
  PaymentMethodEnum,
  PaymentPaypalConfiguration,
  PaymentSIAConfiguration,
  PaypalBillingAgreementInfo,
  PaypalTransactionInfo,
  SmartTopUpPromo,
  StaticEntityKey,
  StepActivationOtpValue,
  StepActivationValue,
  StepAddressValue,
  StepContactsValue,
  StepCustomerValue,
  StepData,
  StepDocumentValue,
  StepPaymentMethodValue,
  StepPaymentValue,
  StepShippingValue,
} from 'models';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StepConsentsValue} from '../../../app-shared/models/steps/step-consents.interface';
import {StepCouponValue} from '../../../app-shared/models/steps/step-coupon.interface';
import {StepFDOIccidValue} from '../../../app-shared/models/steps/step-digital-onboarding.interface';
import {StepOfferExtrasValue} from '../../../app-shared/models/steps/step-offer-extras.interface';
import {StepSimTypeValue} from '../../../app-shared/models/steps/step-sim.interface';
import {SuccessValue} from '../../../app-shared/models/steps/step-success.interface';
import {StepSummaryValue} from '../../../app-shared/models/steps/step-summary.interface';
import {StepWinbackValue} from '../../../app-shared/models/steps/step-winback.interface';
import {RootState} from '../../store';
import {EntityActions, EntitySelectors} from '../../store/entity-feature-store';
import * as PaymentBillingAgreementActions from '../../store/process-feature-store/actions/billing-agreement.actions';
import * as PaymentTransactionActions from '../../store/process-feature-store/actions/payment-transaction.actions';

@Injectable()
export class EntityStoreFacadeService {
  constructor(public store$: Store<RootState.RootState>) { }

  get entity() {
    return {
      // Static entities
      funnelConfiguration$: ((): Observable<FunnelConfiguration> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.FunnelConfiguration)))(),
      debugInfo$: ((): Observable<DebugInfo> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.DebugInfo)))(),
      funnelInfo$: ((): Observable<FunnelInfo> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.FunnelInfo)))(),
      paymentAvailableMethods$: ((): Observable<PaymentAvailableMethods> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.PaymentAvailableMethods)))(),
      orderSummary$: ((): Observable<OrderSummary> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.OrderSummary)))(),
      cart$: ((): Observable<Cart> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.Cart)))(),
      offer$: ((): Observable<Offer> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.Offer)))(),
      offerAlternative$: ((): Observable<Offer> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.OfferAlternative)))(),
      offerPromoInfo$: ((): Observable<OfferPromoInfo> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.OfferPromoInfo)))(),
      customerAvailableDocuments$: ((): Observable<CustomerAvailableDocuments> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.CustomerAvailableDocuments)))(),
      customerAvailableCompanyTypes$: ((): Observable<CustomerAvailableCompanyTypes> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.CustomerAvailableCompanyTypes)))(),
      availableAddons$: ((): Observable<AvailableAddons> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableAddons)))(),
      availableOperators$: ((): Observable<AvailableOperators> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableOperators)))(),
      availableSimTypes$: ((): Observable<AvailableSimTypes> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableSimTypes)))(),
      availableShippingMethods$: ((): Observable<AvailableShippingMethods> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableShippingMethods)))(),
      availableSteps$: ((): Observable<AvailableSteps> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableSteps)))(),
      availableActivationMethods$: ((): Observable<AvailableActivationMethods> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableActivationMethods)))(),
      deliveryAddress$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CustomerDeliveryAddress)))(),
      availableCountries$: ((): Observable<AvailableCountries> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AvailableCountries)))(),
      paymentPaypalConfiguration$: ((): Observable<PaymentPaypalConfiguration> =>
      this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.PaymentPaypalConfiguration)))(),
      paymentSIAConfiguration$: ((): Observable<PaymentSIAConfiguration> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.PaymentSIAConfiguration)))(),
      smartTopUpPromo$: ((): Observable<SmartTopUpPromo> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.SmartTopUpPromo)))(),
      addonsConfig$: ((): Observable<AddonsConfig> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(StaticEntityKey.AddonsConfig)))(),

      // Dynamic entities

      activationMethod$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.ActivationMethod)))(),
      activationMnpInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.ActivationMnpInfo)))(),
      identity$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CustomerIdentity)))(),
      contacts$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CustomerContacts)))(),
      document$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CustomerDocument)))(),
      residenceAddress$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CustomerResidenceAddress)))(),
      paymentMethod$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.PaymentMethod)))(),
      creditCardInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.PaymentCreditCardInfo)))(),
      bankInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.PaymentBankInfo)))(),
      invoiceOptions$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.PaymentInvoiceOptions)))(),
      marketingConsents$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.MarketingConsents)))(),
      generalConsents$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.GeneralConsents)))(),
      winbackInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.WinbackInfo)))(),
      fdoIccid$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.FdoIccid)))(),
      mgmInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.MgmInfo)))(),
      offerExtras$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.OfferExtras)))(),
      selectedAddons$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.SelectedAddons)))(),
      couponInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.CouponInfo)))(),
      shippingMethod$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.ShippingMethod)))(),
      simType$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.SimType)))(),
      paypalTransactionInfo$: ((): Observable<PaypalTransactionInfo> =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.PaypalTransactionInfo)))(),
      otpInfo$: (() =>
        this.store$.select(EntitySelectors.getEntitySelectorByKey(DynamicEntityKey.OtpInfo)))(),

      // Actions
      storeEntityValue: (key: EntityKey, value: EntityValue) => {
        if (value === undefined) {
          console.warn('Skip undefined entity value', key);
          return;
        }
        this.store$.dispatch(EntityActions.storeValue({ key, value }));
      }
    };
  }

  get step() {
    const customer$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.identity$,
        this.entity.generalConsents$,
        // this.entity.marketingConsents$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [customerIdentity, generalConsents/*, marketingConsents*/] = latestValues;
          return { customerIdentity, generalConsents/*, marketingConsents */};
        })
      );
    })();

    const contacts$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.contacts$,
        this.entity.generalConsents$,
        // this.entity.marketingConsents$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ customerContacts, generalConsents/*, marketingConsents*/ ] = latestValues;
          return { customerContacts, generalConsents/*, marketingConsents */ };
        })
      );
    })();

    const consents$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.marketingConsents$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ marketingConsents ] = latestValues;
          return { marketingConsents };
        })
      );
    })();


    const document$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.document$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [customerDocument] = latestValues;
          return { customerDocument };
        })
      );
    })();

    const address$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.residenceAddress$,
        this.entity.deliveryAddress$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [customerResidenceAddress, customerDeliveryAddress] = latestValues;
          return { customerResidenceAddress, customerDeliveryAddress };
        })
      );
    })();

    const payment$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.paymentMethod$
      ]).pipe(
        filter(args => args.every(Boolean)),
        map(latestValues => {
          const [paymentMethod] = latestValues;
          return { paymentMethod };
        })
      );
    })();

    const coupon$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.couponInfo$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ couponInfo ] = latestValues;
          return { couponInfo };
        })
      );
    })();

    const offerExtras$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.offerExtras$,
        this.entity.selectedAddons$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ offerExtras, selectedAddons ] = latestValues;
          return { offerExtras, selectedAddons };
        })
      );
    })();

    const winback$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.winbackInfo$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ winbackInfo ] = latestValues;
          return { winbackInfo };
        })
      );
    })();

    const paymentMethod$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.paymentMethod$,
        this.entity.paymentPaypalConfiguration$,
        this.entity.paymentSIAConfiguration$
      ]).pipe(
        filter(args => args.every(Boolean)),
        map(latestValues => {
          const [paymentMethod] = latestValues;
          return { paymentMethod };
        })
      );
    })();

    const activation$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.activationMethod$,
        this.entity.activationMnpInfo$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [activationMethod, activationMnpInfo] = latestValues;
          return { activationMethod, activationMnpInfo };
        })
      );
    })();

    const activationOtp$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.activationMnpInfo$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [activationMnpInfo] = latestValues;
          return { activationMnpInfo };
        })
      );
    })();

    const fdoIccid$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.fdoIccid$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ fdoIccid ] = latestValues;
          return { fdoIccid };
        })
      );
    })();

    const simType$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.simType$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ simType ] = latestValues;
          return { simType };
        })
      );
    })();

    const shipping$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.shippingMethod$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ shippingMethod ] = latestValues;
          return { shippingMethod };
        })
      );
    })();

    const summary$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.contacts$,
        this.entity.paymentMethod$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ contacts, paymentMethod ] = latestValues;
          return {
            contacts,
            paymentMethod
          };
        })
      );
    })();

    const success$ = ((): Observable<StepData> => {
      return combineLatest([
        this.entity.contacts$,
        this.entity.paymentMethod$
      ]).pipe(
        filter((args) => args.every(Boolean)),
        map((latestValues) => {
          const [ contacts, paymentMethod ] = latestValues;
          return {
            contacts,
            paymentMethod
          };
        })
      );
    })();

    return {
      customer$,
      customerValue$: ((): Observable<StepCustomerValue> => {
        return customer$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepCustomerValue;
          })
        );
      })(),
      storeStepCustomerValue: ({
        customerIdentity,
        generalConsents,
        // marketingConsents
      }: StepCustomerValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.CustomerIdentity, customerIdentity);
        this.entity.storeEntityValue(DynamicEntityKey.GeneralConsents, generalConsents);
        // this.entity.storeEntityValue(DynamicEntityKey.MarketingConsents, marketingConsents);
      },

      document$,
      documentValue$: ((): Observable<StepDocumentValue> => {
        return combineLatest([
          document$,
          this.entity.customerAvailableDocuments$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [stepData, customerAvailableDocuments] = latestValues;
            const documentDynamicValue = inferStepValueGroup(stepData) as StepDocumentValue;
            return { ...documentDynamicValue, customerAvailableDocuments };
          })
        );
      })(),
      storeStepDocumentValue: ({ customerDocument }: StepDocumentValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.CustomerDocument, customerDocument);
      },

      address$,
      addressValue$: ((): Observable<StepAddressValue> => {
        return combineLatest([
          address$,
          this.entity.availableCountries$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [stepData, availableCountries] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepAddressValue;
            return { ...stepDynamicValue, availableCountries };
          })
        );
      })(),
      storeStepAddressValue: ({ customerResidenceAddress, customerDeliveryAddress }: StepAddressValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.CustomerResidenceAddress, customerResidenceAddress);
        this.entity.storeEntityValue(DynamicEntityKey.CustomerDeliveryAddress, customerDeliveryAddress);
      },

      contacts$,
      contactsValue$: ((): Observable<StepContactsValue> => {
        return contacts$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepContactsValue;
          })
        );
      })(),
      storeStepContactsValue: ({
        generalConsents,
        // marketingConsents,
        customerContacts
      }: StepContactsValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.CustomerContacts, customerContacts);
        this.entity.storeEntityValue(DynamicEntityKey.GeneralConsents, generalConsents);
        // this.entity.storeEntityValue(DynamicEntityKey.MarketingConsents, marketingConsents);
      },

      fdoIccid$,
      fdoIccidValue$: ((): Observable<StepFDOIccidValue> => {
        return combineLatest([
          fdoIccid$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ stepData ] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepFDOIccidValue;
            return { ...stepDynamicValue };
          })
        );
      })(),
      storeStepFdoIccidValue: ({ fdoIccid }: StepFDOIccidValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.FdoIccid, fdoIccid);
      },

      activation$,
      activationValue$: ((): Observable<StepActivationValue> => {
        return combineLatest([
          activation$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ stepData ] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepActivationValue;
            return { ...stepDynamicValue };
          })
        );
      })(),
      storeStepActivationValue: ({
        activationMethod,
        activationMnpInfo
      }: StepActivationValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.ActivationMnpInfo, activationMnpInfo);
        this.entity.storeEntityValue(DynamicEntityKey.ActivationMethod, activationMethod);
      },

      activationOtp$,
      activationOtpValue$: ((): Observable<StepActivationOtpValue> => {
        return combineLatest([
          activationOtp$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ stepData ] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepActivationOtpValue;
            return { ...stepDynamicValue };
          })
        );
      })(),
      storeStepActivationOtpValue: ({
        activationMnpInfo
      }: StepActivationOtpValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.ActivationMnpInfo, activationMnpInfo);
      },

      shipping$,
      shippingValue$: ((): Observable<StepShippingValue> => {
        return combineLatest([
          shipping$,
          this.entity.availableShippingMethods$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ stepData, availableShippingMethods ] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepShippingValue;
            return { ...stepDynamicValue, availableShippingMethods };
          })
        );
      })(),
      storeStepShippingValue: ({ shippingMethod }: StepShippingValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.ShippingMethod, shippingMethod);
      },

      simType$,
      simTypeValue$: ((): Observable<StepSimTypeValue> => {
        return combineLatest([
          simType$
          // , this.entity.availableSimTypes$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ stepData/*, availableSimTypes */ ] = latestValues;
            const stepDynamicValue = inferStepValueGroup(stepData) as StepSimTypeValue;
            return { ...stepDynamicValue/*, availableSimTypes */ };
          })
        );
      })(),
      storeStepSimTypeValue: ({ simType }: StepSimTypeValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.SimType, simType);
      },

      payment$,
      paymentValue$: ((): Observable<StepPaymentValue> => {
        return payment$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepPaymentValue;
          })
        );
      })(),

      consents$,
      consentsValue$: ((): Observable<StepConsentsValue> => {
        return consents$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepConsentsValue;
          })
        );
      })(),
      storeStepConsentsValue: ({ marketingConsents }: StepConsentsValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.MarketingConsents, marketingConsents);
      },

      winback$,
      winbackValue$: ((): Observable<StepWinbackValue> => {
        return winback$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepWinbackValue;
          })
        );
      })(),
      storeStepWinbackValue: ({ couponInfo }: StepWinbackValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.WinbackInfo, couponInfo);
      },

      paymentMethod$,
      paymentMethodValue$: ((): Observable<StepPaymentMethodValue> => {
        return paymentMethod$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepPaymentMethodValue;
          })
        );
      })(),
      storeStepPaymentMethodValue: ({ paymentMethod }: StepPaymentMethodValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.PaymentMethod, paymentMethod);
      },
      storePaypalPaymentTransaction: (paypalTransactionInfo: PaypalTransactionInfo) => {
        const paymentMethod =  { method: PaymentMethodEnum.PayPal };
        this.entity.storeEntityValue(DynamicEntityKey.PaymentMethod, paymentMethod);
        this.entity.storeEntityValue(DynamicEntityKey.PaypalTransactionInfo, paypalTransactionInfo);
        this.store$.dispatch(PaymentTransactionActions.savePaypalTransactionInfo({
          paymentMethod,
          paypalTransactionInfo
        }));
      },
      storePaypalBillingAgreement: (paypalBillingAgreementInfo: PaypalBillingAgreementInfo) => {
        const paymentMethod =  { method: PaymentMethodEnum.PayPal };
        this.entity.storeEntityValue(DynamicEntityKey.PaymentMethod, paymentMethod);
        this.entity.storeEntityValue(DynamicEntityKey.PaypalTransactionInfo, paypalBillingAgreementInfo);
        this.store$.dispatch(PaymentBillingAgreementActions.savePaypalBillingAgreementInfo({
          paymentMethod,
          paypalBillingAgreementInfo
        }));
      },

      coupon$,
      couponValue$: ((): Observable<StepCouponValue> => {
        return coupon$.pipe(
          map((stepData: StepData) => {
            return inferStepValueGroup(stepData) as StepCouponValue;
          })
        );
      })(),
      storeStepCouponValue: ({ couponInfo }: StepCouponValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.CouponInfo, couponInfo);
      },

      offerExtras$,
      extrasValue$: ((): Observable<StepOfferExtrasValue> => {
        return combineLatest([
          offerExtras$,
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ offerExtras ] = latestValues;
            const extrasValue = inferStepValueGroup(offerExtras) as StepOfferExtrasValue;
            return { ...extrasValue };
          })
        );
      })(),
      storeStepOfferExtrasValue: ({ offerExtras, selectedAddons }: StepOfferExtrasValue) => {
        this.entity.storeEntityValue(DynamicEntityKey.OfferExtras, offerExtras);
        this.entity.storeEntityValue(DynamicEntityKey.SelectedAddons, selectedAddons);
      },

      summary$,
      summaryValue$: ((): Observable<StepSummaryValue> => {
        return combineLatest([
          summary$,
          this.entity.orderSummary$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ summaryStepData, orderSummary ] = latestValues;
            const dynamicValue = inferStepValueGroup(summaryStepData) as StepSummaryValue;
            return { ...dynamicValue, orderSummary };
          })
        );
      })(),

      success$,
      successValue$: ((): Observable<SuccessValue> => {
        return combineLatest([
          success$,
          this.entity.orderSummary$
        ]).pipe(
          filter((args) => args.every(Boolean)),
          map((latestValues) => {
            const [ successStepData, orderSummary ] = latestValues;
            const dynamicValue = inferStepValueGroup(successStepData) as SuccessValue;
            return { ...dynamicValue, orderSummary };
          })
        );
      })(),
    };
  }
}
